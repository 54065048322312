@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy_Regular.woff2'),
    url('/fonts/gilroy/Gilroy_Regular.woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy_Medium.woff2'),
    url('/fonts/gilroy/Gilroy_Medium.woff');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy_SemiBold.woff2'),
    url('/fonts/gilroy/Gilroy_SemiBold.woff');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy_Bold.woff2'),
    url('/fonts/gilroy/Gilroy_Bold.woff');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: auto;
}

body {
  font-family: 'Gilroy', Helvetica, sans-serif;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 14px; 
}

::-webkit-scrollbar-track {
  background: #C9DDDA;
}

::-webkit-scrollbar-thumb {
  background: #3DAE9F;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: red; 
}

* {
  scrollbar-width: 29px;
  scrollbar-color: #3DAE9F #C9DDDA;
}

h1,
h2,
h3,
h4,
p,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img,
video,
svg {
  display: block;
}

img,
video {
  max-width: 100%;
  height: auto;
}

img[alt] {
  position: relative;
}

img[alt]::before {
  content: attr(alt);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f2f5;
  color: #657281;
  text-align: center;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

button,
input {
  padding: 0;
  background: none;
  border: 0;
  appearance: none;
}

input {
  border-radius: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

button {
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}

a {
  color: inherit;
  text-decoration: none;
}

.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: 0;
}

:where(a[href]:not([tabindex='-1']), area[href]:not([tabindex='-1']), select:not([disabled]):not([tabindex='-1']), button:not([disabled]):not([tabindex='-1']), iframe:not([tabindex='-1']), [tabindex]:not([tabindex='-1']), [contentEditable='true']:not([tabindex='-1'])):focus-visible {
  box-shadow: 0 0 0 3px #0077ea33;
}

.react-joyride__spotlight {
  border-radius: 8px !important;
}

.react-joyride__tooltip {
  border-radius: 8px !important;
  padding: 16px !important;
}
