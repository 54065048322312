.swiper {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
}

.swiper-slide {
  min-width: 270px;
  min-height: 126px;
}

.swiper-pagination-bullet {
  width: 25px !important;
  height: 2px !important;
  border-radius: unset !important;
}

.swiper-pagination-bullet-active {
  background: #3dae9f !important;
}
.swiper-pagination {
  top: 89% !important;
}
.swiper-wrapper {
  margin-bottom: 20px;
}
